import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {

    constructor(private router: Router) { }

    navigateTo(command: any[], extras?: NavigationExtras): Promise<boolean> {
        return this.router.navigate(command, extras);
    }

    navigateToNewTab(command: any[], extras?: NavigationExtras): void {
        const url = this.router.createUrlTree(command, extras);
        window.open(url.toString(), '_blank')
    }

    goToMainPage(extras?: any): Promise<boolean> {
        return this.navigateTo(['/view'], extras);
    }

    goToLoginPage(extras?: any): Promise<boolean> {
        return this.navigateTo(['/login'], extras);
    }

}